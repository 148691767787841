<template>
  <div class="flex">
    <router-link
      :to="{ name: 'master-dish-list' }"
      class="border-b whitespace-no-wrap relative mr-8 flex h-12 items-center border-transparent text-sm text-neutral-600 transition-all duration-300 hover:text-neutral-800"
    >
      <span>
        {{ $t("components.menus.masterDishList") }}
      </span>
    </router-link>
    <router-link
      v-if="$can('write:menu')"
      :to="{ name: 'core-menus' }"
      class="border-b whitespace-no-wrap relative mr-8 flex h-12 items-center border-transparent text-sm text-neutral-600 transition-all duration-300 hover:text-neutral-800"
    >
      <span>
        {{ $t("pages.coreMenus.pageTitle") }}
      </span>
    </router-link>
    <router-link
      v-if="$can('write:changeRequest')"
      :to="{ name: 'change-requests' }"
      class="border-b whitespace-no-wrap relative mr-8 flex h-12 items-center border-transparent text-sm text-neutral-600 transition-all duration-300 hover:text-neutral-800"
    >
      <span>
        {{ $t("components.menus.changeRequests") }}
      </span>
      <span
        v-if="closedChangeRequests.length > 0"
        class="ml-2 inline-flex h-5 items-center justify-center rounded-full bg-primary-600 px-2 text-xs font-bold text-white"
      >
        {{ closedChangeRequests.length }}
      </span>
    </router-link>
    <router-link
      v-if="$can('read:menu') && !$can('write:menu')"
      :to="{ name: 'my-menu' }"
      class="border-b relative mr-8 flex h-12 items-center border-transparent text-sm text-neutral-600 transition-all duration-300 hover:text-neutral-800"
    >
      <span>
        {{ $t("components.menus.myMenu") }}
      </span>
    </router-link>
  </div>
</template>

<style scoped>
a.router-link-exact-active {
  @apply border-primary-600 text-neutral-900;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("changeRequests", ["closedChangeRequests"]),
  },
};
</script>
