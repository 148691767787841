<template>
  <div>
    <Toolbar>
      <template v-slot:title>
        {{ $t("pages.menus.title") }}
      </template>
      <template v-slot:subNavigation>
        <ToolbarNavigation />
      </template>
    </Toolbar>

    <section class="min-h-screen-toolbar py-6 md:py-10">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import ToolbarNavigation from "@/components/menus/ToolbarNavigation";
import store from "@/store";

export default {
  name: "Menus",
  components: {
    ToolbarNavigation,
  },
  beforeRouteEnter(to, _from, next) {
    Promise.all([store.dispatch("changeRequests/fetchChangeRequests")]).finally(
      () => {
        next();
      },
    );
  },
};
</script>
